<template>
    <div>
        <section class="checkout">
            <div class="half_inner height_half_top_inner height_bottom_inner">
                <header class="section_header header_flex"  v-if="pedido">
                    <h2 class="text_xxl text_local text_300">Resumen de pedido</h2>
                    <div class="breadcrumbs inverted">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <router-link to="/cuenta" class="breadcrumbs_item">Mi cuenta<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">{{pedido.ref}}</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div class="checkout_info" v-if="pedido">
                    <div class="grid grid_pad grid_wrap">
                        <div class="grid_col grid_col_1_1">
                            <div class="grid_col_box checkout_info_line">
                                <div class="checkout_info_line_item">
                                    <div class="info_header">
                                        <span>Pedido nº: </span>
                                        <span>{{pedido.ref}}</span>
                                    </div>
                                    <div class="info_content">
                                        <h3 class="text_s mb5">Datos de envío</h3>
                                        <p>{{pedido.address.name}} {{pedido.address.lastname}}</p>
                                        <p>{{pedido.address.address}}</p>
                                        <p>{{pedido.address.city}} {{pedido.address.cp}} {{provincia}}</p>
                                        <p>{{pedido.address.country.name}}</p>
                                        <p>{{pedido.address.phone}}</p>
                                    </div>
                                </div>
                                <div class="checkout_info_line_item">
                                    <div class="info_header">
                                        <span>Fecha: </span>
                                        <span>{{pedido.created_at}}</span>
                                    </div>
                                    <div class="info_content">
                                        <h3 class="text_s mb5">Datos de Facturación</h3>
                                        <p>{{pedido.address.name}} {{pedido.address.lastname}}</p>
                                        <p>{{pedido.address.address}}</p>
                                        <p>{{pedido.address.city}} {{pedido.address.cp}} {{provincia}}</p>
                                        <p>{{pedido.address.country.name}}</p>
                                        <p>{{pedido.address.phone}}</p>
                                    </div>
                                </div>
                                <div class="checkout_info_line_item">
                                    <div class="info_header">
                                        <span>Estado: </span>
                                        <span class="checkout_status sent"></span>
                                    </div>
                                    <div class="info_content">
                                        <h3 class="text_s mb5">Forma de pago</h3>
                                        <p class="mb20" v-if="pedido.payment_type == 1">Tarjeta de crédito / débito</p>
                                        <h3 class="text_s mb5">Método de envío</h3>
                                        <p v-if="pedido.delivery_type == 1">Metodo ordinario: de 2-7 dias laborables</p>
                                        <p v-else-if="pedido.delivery_type == 2">Metodo express: de 1-2 dias laborables</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_1">
                            <div class="grid_col_box checkout_info_table">
                                <div class="checkout_info_table_line line_legend">
                                    <div class="units">Unidades</div>
                                    <div class="ref_num">Referencia</div>
                                    <div class="product_desc">Producto</div>
                                    <div class="price">Precio total</div>
                                </div>
                                <div class="checkout_info_table_line" v-for="(producto, index) in pedido.products" :key="index">
                                    <div class="units">{{producto.quantity}}</div>
                                    <div class="ref_num">256639</div>
                                    <div class="product_desc">{{producto.name}}</div>
                                    <div class="price">{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(producto.price * producto.quantity)}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_1">
                            <div class="grid_col_box checkout_final_info">
                                <div class="checkout_final_info_line">
                                    <span>Subtotal</span>
                                    <span>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(getSubTotal())}}</span>
                                </div>
                                <div class="checkout_final_info_line">
                                    <span>Gastos de envío</span>
                                    <span>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(pedido.delivery_cost)}}</span>
                                </div>
                                <div class="checkout_final_info_line total_line">
                                    <span>Total</span>
                                    <span>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(getTotal())}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="nuevo">
                    <router-link to="/productos" class="btn med wide center grey mt30 mb10">Seguir comprando</router-link>
                    <p class="text_xs legal" style="text-align: center;">Al completar tu pedido aceptas nuestras <router-link to="/legal">Condiciones de uso y venta.</router-link></p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import VityCheck from '../components/VityCheck.vue'

export default {
  components: { VityCheck },
    name: 'Pedido',
    data: function(){
        return {
            pedido: null,
            provincia: null,
            nuevo: false
        }
    },
    methods: {
        get: function(ref){
            axios
                .get(process.env.VUE_APP_URL+`orders/${ref}`, {
                    params: {
                        products: 1,
                        address: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.pedido = rs.data;
                        this.getComunidad();
                    }
                    else
                        this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion';
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status == 404)
                            this.$router.push('/404');
                    }
                    else
                        this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion';
                })
        },
        getComunidad: function() {
            axios
                .get(process.env.VUE_APP_GEOLOCATION+'provincias', {
                    params: {
                        key: 'f70b1f7c791d56687366549ba7ddcd9e61c469a028ba96c2248f14497bd2c633',
                    }
                })
                .then(response => {
                    response.data.data.forEach(element => {
                        if(element.CPRO == this.pedido.address.province){
                            this.provincia = element.PRO;
                            return;
                        }
                    });
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        getSubTotal: function(){
            let precio = 0;
            this.pedido.products.forEach((x) => {
                precio += x.price * x.quantity;
            });
            return precio;
        },
        getTotal: function(){
            let precio = 0;
            this.pedido.products.forEach((x) => {
                precio += x.price * x.quantity;
            });
            precio += this.pedido.delivery_cost;
            return precio;
        },
    },
    created: function(){
        if(this.$route.params.ref){
            if(this.$route.params.ref == '7errAp1anism0'){
                this.pedido = {
                    ref: 12345678,
                    created_at: '2021/03/31 01:13:19',
                    address: {
                        name: 'Victor',
                        lastname: 'Anton Bescos',
                        phone: '620796027',
                        address: 'Avenida Valdelasfuentes, 19 local, San Sebastian de los Reyes',
                        cp: '28701',
                        country: {
                            id: 1,
                            name: 'España',
                            express: 7.99,
                            ordinary: 4.95
                        },
                        province: '28',
                        city: 'SAN SEBASTIÁN DE LOS REYES'
                    },
                    delivery_type: 1,
                    delivery_cost: 4.95,
                    payment_type: 1,
                    products: [
                        {
                            quantity: 1,
                            name: 'Camiseta #YoSoyBonobo XL rojo',
                            price: 25.99,
                        },
                        {
                            quantity: 75,
                            name: 'Camiseta #YoSoyBonobo XL rojo',
                            price: 25.99,
                        }
                    ]
                }
                this.getComunidad();
            }
            else{
                if(this.$route.query.address && this.$route.query.send && this.$route.query.pay){
                    this.nuevo = true;
                    axios
                        .post(process.env.VUE_APP_URL+'orders', {
                            address: this.$route.query.address,
                            user: this.$store.state.user,
                            send: this.$route.query.send,
                            pay: this.$route.query.pay,
                            productos: JSON.stringify(this.$store.state.cart)
                        })
                        .then(response => {
                            let rs = response.data;
                            if(rs.state == 'OK'){
                                this.$store.state.cart = [];
                                this.get(this.$route.params.ref);
                            }
                        })
                        .catch()
                }
                else
                    this.get(this.$route.params.ref);
            }
        }
        else
            this.$router.push('/');
    }
}
</script>